import { Box, Button, Container, Stack, Typography } from '@mui/material'

import { ReactComponent as WelcomeImage } from '../icons/welcome.svg'
import { rootStore } from '../store/RootStore'
import { observer } from 'mobx-react'

const Welcome = () =>
  <Container maxWidth={'xl'}>
    <WelcomeImage style={{ position: 'fixed', transform: `translate(200px, 0px)` }}/>
    <Box maxWidth={'sm'} sx={{ pt: '120px', ml: '70px' }}>
      <Stack direction={'column'}>
        <Typography component="h1" variant="h3" sx={{ fontWeight: 700, fontSize: 50, lineHeight: '60px' }}>
          California Meal
        </Typography>
        <Typography component="h1" variant="h1" sx={{ fontWeight: 860, fontSize: 80, lineHeight: '96px' }}>
          Violations Checker
        </Typography>
        <Typography variant="subtitle1" component={'div'}
                    sx={{
                      fontSize: 20,
                      lineHeight: 1.5,
                      fontWeight: 280,
                      borderLeft: `3px solid #1A56DB`,
                      mt: '25px',
                      pl: '10px',
                      maxWidth: '50rem',
                      '& p': { mb: 0 },
                      '& p:first-of-type': { mt: 0 },
                    }}>
          <p>You can analyze your employees' time records for compliance with California meal break requirements.</p>
          <p>We provide this functionality for free.</p>
          <p>Your data is not permanently stored, it is processed directly in browser and removed after your web
            session expires.</p>
          <p>The process is simple - prepare your data using the provided template, upload it and see your results right
            away on the page. Details of the analysis will be available for immediate download.</p>
        </Typography>
        <Box sx={{ mt: '40px' }}>
          <Button variant={'contained'} onClick={() => rootStore.toggleWelcomeScreen(false)} sx={{
            fontSize: 24,
            lineHeight: 1,
            py: '16px',
            px: '32px',
            textTransform: 'capitalize',
            backgroundColor: '#1A56DB',
            borderRadius: '8px'
          }}>I am ready to start the process</Button>
        </Box>
      </Stack>
    </Box>
  </Container>

export default observer(Welcome)
