import { styled, Tab, Tabs } from '@mui/material'

export const StyledTabs = styled(Tabs)({
  '& .MuiTabs-scroller': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  '& .MuiTabs-flexContainer': {
    marginTop: '15vh',
    alignItems: 'flex-end',
  }
})

export const StyledTab = styled(Tab)({
  '&.MuiTab-root': {
    height: '13vh',
    textTransform: 'none',
  }
})
