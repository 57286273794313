import ColumnsTable from './ColumnsTable'
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link, Typography } from '@mui/material'
import { FilePresent } from '@mui/icons-material'
import { observer } from 'mobx-react'
import { ChangeEvent } from 'react'

const ReportDownloadDialog = ({ isOpen, handleClose, hideDialog, handleChange }: {
  isOpen: boolean,
  handleClose: () => void,
  hideDialog: boolean,
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void
}) => {

  const downloadDescriptionButton = (
    <Link download sx={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'bottom' }}
          href="./dictionary/CA MVC output data format.pdf">
      <FilePresent/> PDF File
    </Link>
  )

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth={'md'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">You are downloading a detailed results</DialogTitle>
      <DialogContent dividers>
        <Typography sx={{ mb: 1 }}>After performing validation of your data for California Meal time requirements, we
          are making detailed results available to you via download of a resulting CSV file.
        </Typography>
        <Typography sx={{ mb: 1 }}>Calculated columns are appended after all data columns which were present in the
          original file.</Typography>
        <Typography sx={{ mb: 1 }}>You can download this description as {downloadDescriptionButton}</Typography>

        <ColumnsTable/>
      </DialogContent>
      <DialogActions>
        <FormControlLabel control={<Checkbox checked={hideDialog} onChange={handleChange} />} label="Do not show this screen" />
        <Button variant="contained" color="success" onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(ReportDownloadDialog)
