import { Alert, Box, Button, CircularProgress, Stack, Tooltip, Typography } from '@mui/material'
import { rootStore, STEPS } from '../../store/RootStore'
import { observer } from 'mobx-react'
import { ArrowLeft, Check } from '@mui/icons-material'
import React, { ChangeEvent, useEffect, useState } from 'react'
import ReportDownloadDialog from '../../Widgets/Report/ReportDownloadDialog'

const HIDE_DOWNLOAD_DIALOG = 'hideDownloadDialog'

const Report = () => {
  const [downloadInProgress, setDownloadInProgress] = useState(false)
  const initialState = localStorage.getItem(HIDE_DOWNLOAD_DIALOG)
  const [hideDialog, setHideDialog] = useState(initialState === 'true')

  const complete = () => {
    rootStore.setFile(undefined)
    rootStore.setActiveStep(STEPS.UPLOAD)
    setDownloadInProgress(false)
  }

  const reportIsExported = rootStore.isReady && !rootStore.isExporting

  useEffect(() => {
    if (reportIsExported && !hideDialog) {
      setDownloadInProgress(true)
    }
  }, [reportIsExported, hideDialog])

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setHideDialog(event.target.checked)
    if (event.target.checked) {
      localStorage.setItem(HIDE_DOWNLOAD_DIALOG, 'true')
    } else {
      localStorage.removeItem(HIDE_DOWNLOAD_DIALOG)
    }
  }

  return <Box>
    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>Step 4</Typography>
    <Typography variant={'h4'}>Download detailed results</Typography>
    <Typography variant="body1" sx={{ my: 2 }}>
      Thank you for using our free California Meal Violation Checker
    </Typography>

    {rootStore.state.file && <Alert severity={'info'} sx={{ my: 2 }}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Typography sx={{ fontWeight: 500 }} variant={'body1'}>{rootStore.state.file.name}</Typography>
        {rootStore.isReady && rootStore.isExporting && <>
          <CircularProgress size={20}/>
          <Typography>Generate report</Typography>
        </>}
      </Stack>
    </Alert>}

    {reportIsExported && <Stack direction={'row'} gap={4} sx={{ my: 2 }}>
      <Button variant={'outlined'} startIcon={<ArrowLeft/>} onClick={() => rootStore.prevStep()}>Back to
        Summary</Button>
      <Tooltip arrow title={<Box sx={{ typography: 'body2' }}>Remove all data and return to Upload step</Box>}>
        <Button variant={'contained'} onClick={() => complete()} startIcon={<Check/>}>Done</Button>
      </Tooltip>
    </Stack>}

    <ReportDownloadDialog
      isOpen={downloadInProgress}
      handleClose={() => setDownloadInProgress(false)}
      hideDialog={hideDialog}
      handleChange={handleChange}
    />
  </Box>
}

export default observer(Report)
