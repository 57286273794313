import ColumnsTable from './ColumnsTable'
import React, { useEffect, useRef } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Link,
  Typography
} from '@mui/material'
import { ArrowRight, FilePresent } from '@mui/icons-material'
import { rootStore, STEPS } from '../../store/RootStore'
import { observer } from 'mobx-react'
import { MAX_ROWS } from '../../store'

const DownloadStartedDialog = ({ isOpen, handleClose }: {
  isOpen: boolean,
  handleClose: () => void
}) => {

  const descriptionElementRef = useRef<HTMLElement>(null)
  useEffect(() => {
    if (isOpen) {
      const { current: descriptionElement } = descriptionElementRef
      if (descriptionElement !== null) {
        descriptionElement.focus()
      }
    }
  }, [isOpen])

  const downloadDescriptionButton = (
    <Link download sx={{ display: 'inline-flex', alignItems: 'center', verticalAlign: 'bottom' }} href="./dictionary/CA MVC incoming data format.pdf">
      <FilePresent/> PDF File
    </Link>
  )

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      scroll={'paper'}
      maxWidth={'md'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
    >
      <DialogTitle id="scroll-dialog-title">You are downloading a template file in CSV format</DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          id="scroll-dialog-description"
          ref={descriptionElementRef}
          tabIndex={-1}
        >
          <Typography sx={{ mb: 1 }}>There is one data row for your reference. Keep the header row, but remove the
            sample data row and add your own data to this file. Each row represents one shift for one
            employee.</Typography>

          <Typography sx={{ mb: 1 }}>You can download this description as {downloadDescriptionButton}</Typography>

          <ColumnsTable/>

          <Typography sx={{ mt: 1 }}>Please be aware that this free service is able to process limited data volume.
            Ensure that your
            file size does not exceed {MAX_ROWS.toLocaleString()} records</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Go back</Button>
        <Button variant="contained" color="success" onClick={() => rootStore.setActiveStep(STEPS.UPLOAD)}>
          Next <ArrowRight/>
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default observer(DownloadStartedDialog)
