import { rootStore } from '../store/RootStore'
import React, { useState } from 'react'
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@mui/material'
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

export function SummaryTable () {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleButton = (
    <Button color={'warning'}
            variant={'text'}
            size={'small'}
            endIcon={isExpanded ? <ArrowDropUp/> : <ArrowDropDown/>}
            onClick={() => setIsExpanded(!isExpanded)}>
      {isExpanded ? 'Collapse' : 'Expand'}
    </Button>
  )

  const invalidRecordsColumns: GridColDef[] = [
    {
      width: 100,
      headerName: 'Line',
      field: 'rowIndex',
      sortable: false
    },
    {
      headerName: 'Error',
      field: 'error',
      flex: 2,
      sortable: false
    }]

  return (
    <>
      {rootStore.invalidRecords.length > 0 && (
        <Alert severity="warning" sx={{ my: 2, '& .MuiAlert-message': { flex: 2 } }}>
          <AlertTitle>{rootStore.state.summary?.invalidRecords.length.toLocaleString()} records have missing/invalid
            data {toggleButton}</AlertTitle>
          {isExpanded && (
            <Box sx={{ mt: 1 }}>
              <DataGrid
                autoHeight
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: Math.min(rootStore.invalidRecords.length, 10),
                    },
                  },
                }}
                pageSizeOptions={[Math.min(rootStore.invalidRecords.length, 10)]}
                density={'compact'}
                rows={rootStore.invalidRecords}
                columns={invalidRecordsColumns}
                getRowId={(row) => row.rowIndex}
                disableColumnMenu
                disableRowSelectionOnClick
                hideFooter={rootStore.invalidRecords.length < 10}
              />
            </Box>
          )}
        </Alert>
      )}

      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table size={'small'}>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                Total data rows in uploaded file
              </TableCell>
              <TableCell align="right">
                {rootStore.state.summary?.records.toLocaleString()}
              </TableCell>
            </TableRow>
            {(!!rootStore.state.summary?.invalidRecords?.length) && (
              <TableRow>
                <TableCell component="th" scope="row" sx={{ color: 'error.main' }}>
                  Invalid rows, removed
                </TableCell>
                <TableCell align="right" sx={{ color: 'error.main' }}>
                  {rootStore.state.summary?.invalidRecords?.length.toLocaleString()}
                </TableCell>
              </TableRow>
            )}
            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row" sx={{ fontWeight: 500 }}>
                Total number of data rows used in validation
              </TableCell>
              <TableCell align="right" sx={{ fontWeight: 500 }}>
                {rootStore.state.summary?.validRecords.toLocaleString()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table size={'small'}>
          <TableBody>
            <TableRow>
              <TableCell colSpan={3} component={'th'}
                         sx={{ fontWeight: 500, backgroundColor: 'primary.main', color: '#fff', py: 1 }}>Validation
                results</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} component={'th'} sx={{ fontWeight: 500, backgroundColor: 'secondary.main' }}>First
                meal break in shift</TableCell>
            </TableRow>
            <MealSummary mealSummaryKey={'meal1'}/>
            <TableRow>
              <TableCell colSpan={3} component={'th'} sx={{ fontWeight: 500, backgroundColor: 'secondary.main' }}>Second
                meal break in shift</TableCell>
            </TableRow>
            <MealSummary mealSummaryKey={'meal2'}/>
            <TableRow>
              <TableCell colSpan={3} component={'th'}
                         sx={{ fontWeight: 500, backgroundColor: 'secondary.main' }}>Summary</TableCell>
            </TableRow>
            <SummaryRow label={'Total meal violations'} value={rootStore.state.summary?.violations} asHeader
                        markAsError/>
            <SummaryRow label={'Overall percent meal violations'} value={rootStore.state.summary?.violationsPercentage}
                        asHeader asPercents/>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const MealSummary = ({ mealSummaryKey }: { mealSummaryKey: string }) => {
  const mealSummary = mealSummaryKey === 'meal1'
    ? rootStore.state.summary?.meal1
    : rootStore.state.summary?.meal2

  if (!mealSummary) {
    return null
  }

  return <>
    <SummaryRow label={`Number of shifts with ${mealSummaryKey === 'meal1' ? 'first' : 'second'} meal break required`} value={mealSummary.required} asHeader/>
    <SummaryRow label={`Meal break not taken`} value={mealSummary.missed} markAsError/>
    <SummaryRow label={`Meal break taken late`} value={mealSummary.late} markAsError/>
    <SummaryRow label={`Meal break too short`} value={mealSummary.short} markAsError/>
    <SummaryRow label={`Total first meal break violations`} value={mealSummary.violations} markAsError asHeader/>
  </>
}

function SummaryRow ({ label = '', value = 0, markAsError = false, asHeader = false, asPercents = false }) {
  const hasError = markAsError && value > 0
  const color = hasError ? { color: 'error.main' } : {}
  const font = asHeader ? { fontWeight: '500' } : {}
  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell/>
      <TableCell component={asHeader ? 'th' : 'td'} sx={{ ...color, ...font }}>{label}</TableCell>
      <TableCell align={'right'} component={asHeader ? 'th' : 'td'}
                 sx={{ ...color, ...font }}>{asPercents ? `${value?.toFixed(2)}%` : value?.toLocaleString()}</TableCell>
    </TableRow>
  )
}
