import { useAuth0 } from '@auth0/auth0-react'
import {
  AppBar,
  Box,
  Button,
  Container,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography
} from '@mui/material'
import { AccountCircle, Logout } from '@mui/icons-material'
import { rootStore } from '../store/RootStore'
import { MouseEvent, useCallback, useEffect, useState } from 'react'
import { ReactComponent as Logo } from '../icons/logo.svg'

const Header = () => {
  const { user = {}, isAuthenticated, logout } = useAuth0()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const handleMenu = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const logoutAction = useCallback(async () => {
    await rootStore.erase()
    handleClose()
    logout()
  }, [logout])

  useEffect(() => {
    if (isAuthenticated) {
      rootStore.setUser(user)
    }
  }, [user, isAuthenticated])

  return (
    <AppBar
      sx={{ position: 'sticky', top: 0, backgroundColor: '#fff', borderBottom: '1px solid #1A56DB', boxShadow: 0 }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Link href="/" onClick={() => rootStore.toggleWelcomeScreen(true)} sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            textDecoration: 'none',
            flexGrow: 1
          }}>
            <Logo width={36} height={36}/>
            <Typography
              variant="h6"
              noWrap
              sx={{
                fontWeight: 500,
                fontSize: '32px',
                ml: 1,
                color: 'primary.main',
                textDecoration: 'none',
              }}
            >
              MVC
            </Typography>
          </Link>

          {isAuthenticated && (
            <Box>
              <Button sx={{ color: 'primary.main', textTransform: 'lowercase' }} onClick={handleMenu}
                      startIcon={<AccountCircle/>}>{user.email}</Button>
              <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => logoutAction()}>
                  <ListItemIcon><Logout fontSize="small"/></ListItemIcon>
                  <ListItemText>Logout</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Header
