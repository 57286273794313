export const READ_FILE = 'READ_FILE'
export const FILE_READING_STARTED = 'FILE_READING_STARTED'
export const FILE_READING_COMPLETE = 'FILE_READING_COMPLETE'
export const START_CALCULATION = 'START_CALCULATION'
export const CALCULATION_STARTED = 'CALCULATION_STARTED'
export const CALCULATION_PROGRESS = 'CALCULATION_PROGRESS'
export const CALCULATION_COMPLETE = 'CALCULATION_COMPLETE'
export const SAVING_STARTED = 'SAVING_STARTED'
export const SAVING_COMPLETE = 'SAVING_COMPLETE'
export const ERROR = 'ERROR'
export const REQUEST_SAVED_STATE = 'REQUEST_SAVED_STATE'
export const FOUND_SAVED_STATE = 'FOUND_SAVED_STATE'
export const RECEIVE_SAVED_STATE = 'RECEIVE_SAVED_STATE'
export const ERASE_REPORT = 'ERASE_REPORT'
export const REPORT_ERASED = 'REPORT_ERASED'
export const GENERATE_CSV = 'GENERATE_CSV'
export const GENERATE_CSV_IS_COMPLETE = 'GENERATE_CSV_IS_COMPLETE'
export const DATE_TIME_COLUMNS = ['FIRSTIN', 'FIRSTOUT', 'SECONDIN', 'SECONDOUT', 'THIRDIN', 'THIRDOUT']
export const FLAG_COLUMNS = ['IDENTIFY INVALID ROW', 'M1REQ', 'M1MISSED', 'M1LATE', 'M1SHORT', 'M1VIOL', 'M2REQ', 'M2MISSED', 'M2LATE', 'M2SHORT', 'M2VIOL', 'MEALVIOLATION']
export const HIDDEN_COLUMNS = ['errors']

const MB = 1024 * 1024
export const MAX_FILE_SIZE = 30 * MB // 30MiB
export const MAX_ROWS = 100_000
export const ALLOWED_MIME_TYPES = [
  'application/csv',
  'application/x-csv',
  'text/comma-separated-values',
  'text/x-comma-separated-values',
  'text/csv',
  'text/x-csv',
  'text/tab-separated-values',
]

export const SESSION_STORAGE_KEY = 'dataIsProcessing'
export const LOCAL_STORAGE_WELCOME_SCREEN_IS_HIDDEN = 'welcomeScreenIsHidden'
