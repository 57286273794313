import { observer } from 'mobx-react'
import Header from './components/Header'
import Welcome from './components/Welcome'

import CssBaseline from '@mui/material/CssBaseline'
import theme from './theme'
import { rootStore } from './store/RootStore'
import Example from './Pages/Steps/Example'
import { Box, Grid, ThemeProvider } from '@mui/material'
import Upload from './Pages/Steps/Upload'
import Summary from './Pages/Steps/Summary'
import Report from './Pages/Steps/Report'
import Tabs from './components/Tabs/Tabs'
import Footer from './components/Footer'

const STEPS = {
  EXAMPLE: 0,
  UPLOAD: 1,
  SUMMARY: 2,
  REPORT: 3
}

const App = () => {
  return <ThemeProvider theme={theme}>
    <CssBaseline/>
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    }}>
      <Header/>
      {rootStore.welcomeScreen
        ? <Welcome/>
        : <Grid container spacing={2}>
          <Grid item xs={4}>
            <Tabs/>
          </Grid>
          <Grid item xs={8} maxWidth={'xl'}>
            <Box maxWidth="sm" minHeight="400px" sx={{ mx: 'auto', mt: '15vh' }}>
              {rootStore.activeStep === STEPS.EXAMPLE && <Example/>}
              {rootStore.activeStep === STEPS.UPLOAD && <Upload/>}
              {rootStore.activeStep === STEPS.SUMMARY && <Summary/>}
              {rootStore.activeStep === STEPS.REPORT && <Report/>}
            </Box>
          </Grid>
        </Grid>
      }
      <Footer/>
    </Box>
  </ThemeProvider>
}

export default observer(App)
