import { Box, Button, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import DownloadStartedDialog from '../../Widgets/Example/DownloadStartedDialog'
import { ArrowRight, FileDownload } from '@mui/icons-material'
import { observer } from 'mobx-react'
import { rootStore, STEPS } from '../../store/RootStore'
import { MAX_ROWS } from '../../store'

const Example = () => {
  const [downloadInProgress, setDownloadInProgress] = useState(false)

  const handleDownloadTemplate = () => {
    setDownloadInProgress(true)
  }

  return <Box>
    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>Step 1</Typography>
    <Typography variant={'h4'}>Prepare your data</Typography>
    <Typography variant="body1" sx={{ my: 3 }}>
      In order to use California Meal Violation Checker (MVC), please prepare your data in an expected format.
      Download the CSV template and use it as a guide for formatting your timesheet data. In the same download you will
      find a data dictionary and instructions.
    </Typography>
    <Typography variant="body1" sx={{ my: 3 }}>
      Keep in mind that this version of the MVC can work with files containing no more
      than {MAX_ROWS.toLocaleString()} rows of data.
    </Typography>
    <Typography variant="body1" sx={{ my: 3 }}>
      If you already have your data in the expected format, just skip to the next step
    </Typography>

    <Stack direction={'row'} sx={{ mt: 3 }} gap={4}>
      <Button variant={'contained'} href="./meal violation checker template.csv" onClick={handleDownloadTemplate}
              startIcon={<FileDownload/>}>
        Download template file
      </Button>

      <Button variant={'outlined'} onClick={() => rootStore.setActiveStep(STEPS.UPLOAD)} endIcon={<ArrowRight/>}>
        Skip
      </Button>
    </Stack>

    <DownloadStartedDialog isOpen={downloadInProgress} handleClose={() => setDownloadInProgress(false)}/>
  </Box>
}

export default observer(Example)
