import { observer } from 'mobx-react'
import { rootStore } from '../../store/RootStore'
import React, { useCallback } from 'react'
import { Alert, AlertTitle, Box, Button, LinearProgress, Stack, Typography } from '@mui/material'
import { SummaryTable } from '../../components/SummaryTable'
import { useAuth0 } from '@auth0/auth0-react'
import DownloadButton from '../../components/DownloadButton'
import { ArrowLeft } from '@mui/icons-material'
import { SESSION_STORAGE_KEY } from '../../store'

const Summary = () => {
  const { isAuthenticated, loginWithRedirect, isLoading } = useAuth0()

  const login = useCallback(() => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    loginWithRedirect()
  }, [loginWithRedirect])

  return <Box>
    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>Step 3</Typography>
    <Typography variant={'h4'}>Summary of your results</Typography>

    {(rootStore.isProcessing || rootStore.isPreparing) &&
      <Alert severity="info" sx={{ my: 2, '& .MuiAlert-message': { flex: 2 } }}>
        <AlertTitle>
          <Typography sx={{ fontWeight: 500 }} variant={'body1'}>{rootStore.state.file?.name}</Typography>
          <Typography variant={'body2'}>Data is being analyzed</Typography>
        </AlertTitle>
        <Typography variant={'subtitle1'}>{Math.ceil(rootStore.progress.processed / rootStore.progress.total * 100)}%
          of {rootStore.progress.total.toLocaleString()} records</Typography>
        <LinearProgress variant="determinate" sx={{ width: '100%' }}
                        value={Math.ceil(rootStore.progress.processed / rootStore.progress.total * 100)}/>
      </Alert>}

    {rootStore.isReady && <>
      <Alert severity={'info'} sx={{ my: 2, '& .MuiAlert-message': { flex: 2 } }}>
        <Typography sx={{ fontWeight: 500 }} variant={'body1'}>{rootStore.state.file?.name}</Typography>
      </Alert>
      <SummaryTable/>
      <Box sx={{ mt: 2, height: '15vh', p: 1, backgroundColor: 'white' }}>
        <Stack direction={'row'} spacing={4}>
          <Button variant={'outlined'} startIcon={<ArrowLeft/>} onClick={() => rootStore.prevStep()}>
            Back to Upload
          </Button>
          {(isAuthenticated && !isLoading) ? (
            <DownloadButton/>
          ) : (
            <Button variant={'contained'} onClick={login}>
              Login to view details
            </Button>
          )}
        </Stack>
      </Box>
    </>}
  </Box>
}

export default observer(Summary)
