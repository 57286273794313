import { format, isValid, millisecondsToMinutes, parse } from 'date-fns'

// NOTE Use minutes for calculation to avoid errors
const BREAK1_WORKING_HOURS = 5 * 60
const BREAK1_MIN_LENGTH = 30

const BREAK2_WORKING_HOURS = 10 * 60
const BREAK2_MIN_LENGTH = 30

const MAX_DIFF_BETWEEN_DATES = 24 * 60 * 60 * 1000

export const REQUIRED_FIELDS = ['SHIFTDT', 'FIRSTIN', 'FIRSTOUT']
export const ID_COLUMN = 'EMPID'

function parseDate (dateSting: string) {
  return new Date(dateSting)
}

function parseTime (timeString: string, refDate: Date) {
  if (timeString.split(':').length === 2) {
    timeString = timeString.concat(':00')
  }
  return parse(timeString.split('.')[0], 'H:m:s', refDate)
}

function parseDateTime (dateTimeString: string, shiftDate: Date) {
  if (!dateTimeString) {
    return undefined
  }

  if (!isNaN(Date.parse(dateTimeString))) {
    return parseDate(dateTimeString)
  }

  let date = shiftDate
  let [dateString, timeString] = dateTimeString.split(' ')
  if (dateString && timeString) {
    return parseDate(dateTimeString)
  } else {
    return parseTime(dateTimeString, date)
  }
}

function formatDate (date: Date | undefined) {
  if (!date) return undefined
  return format(date, 'MM/dd/yyyy')
}

function formatDateTime (date: Date | undefined) {
  if (!date) return undefined
  return format(date, 'MM/dd/yyyy HH:mm:ss')
}

function hasError (date: Date | undefined, prevDate?: Date | undefined, prevDateFieldName?: string, canBeEqual = false) {
  if (!date) {
    return 'is empty'
  } else if (!isValid(date)) {
    return 'is invalid'
  } else if (prevDate && date.valueOf() < prevDate.valueOf()) {
    return `should be greater than ${prevDateFieldName || 'previous value'}`
  } else if (prevDate && date.valueOf() === prevDate.valueOf() && !canBeEqual) {
    return `is the same as ${prevDateFieldName || 'previous value'}`
  } else if (prevDate && date.valueOf() - prevDate.valueOf() > MAX_DIFF_BETWEEN_DATES) {
    return `is too far from ${prevDateFieldName || 'previous value'}`
  }
  return undefined
}

function formatRecord (original: any, result: any, errors: Array<string>) {
  const {
    SHIFTDT,
    FIRSTIN,
    FIRSTOUT,
    SECONDIN,
    SECONDOUT,
    THIRDIN,
    THIRDOUT,
    SHIFTTLHRS,
    SEG1LENGTH,
    SEG2LENGTH,
    SEG3LENGTH,
    ...otherColumns
  } = result

  return {
    ...original,
    ...otherColumns,
    ...(SHIFTDT && isValid(SHIFTDT) ? { SHIFTDT: formatDate(SHIFTDT) } : {}),
    ...(FIRSTIN && isValid(FIRSTIN) ? { FIRSTIN: formatDateTime(FIRSTIN) } : {}),
    ...(FIRSTOUT && isValid(FIRSTOUT) ? { FIRSTOUT: formatDateTime(FIRSTOUT) } : {}),
    ...(SECONDIN && isValid(SECONDIN) ? { SECONDIN: formatDateTime(SECONDIN) } : {}),
    ...(SECONDOUT && isValid(SECONDOUT) ? { SECONDOUT: formatDateTime(SECONDOUT) } : {}),
    ...(THIRDIN && isValid(THIRDIN) ? { THIRDIN: formatDateTime(THIRDIN) } : {}),
    ...(THIRDOUT && isValid(THIRDOUT) ? { THIRDOUT: formatDateTime(THIRDOUT) } : {}),
    ...(SHIFTTLHRS ? { SHIFTTLHRS: (SHIFTTLHRS / 60).toFixed(2) } : {}),
    ...(SEG1LENGTH ? { SEG1LENGTH: (SEG1LENGTH / 60).toFixed(2) } : {}),
    ...(SEG2LENGTH ? { SEG2LENGTH: (SEG2LENGTH / 60).toFixed(2) } : {}),
    ...(SEG3LENGTH ? { SEG3LENGTH: (SEG3LENGTH / 60).toFixed(2) } : {}),
    errors
  }
}

type CalculatedRecord = {
  errors: Array<any>,
  SHIFTDT: Date | undefined,

  FIRSTIN: Date | undefined,
  FIRSTOUT: Date | undefined,

  SECONDIN: Date | undefined,
  SECONDOUT: Date | undefined,

  THIRDIN: Date | undefined,
  THIRDOUT: Date | undefined,

  'IDENTIFY INVALID ROW': boolean,

  SHIFTTLHRS: number,

  SEG1LENGTH: number,
  SEG2LENGTH: number,
  SEG3LENGTH: number,

  BREAK1LENGTH: number,
  BREAK2LENGTH: number,

  M1REQ: boolean,
  M1MISSED: boolean,
  M1LATE: boolean,
  M1LATEBY: number,
  M1SHORT: boolean,
  M1SHORTBY: number
  M1VIOL: boolean

  M2REQ: boolean,
  M2MISSED: boolean,
  M2LATE: boolean,
  M2LATEBY: number,
  M2SHORT: boolean,
  M2SHORTBY: number
  M2VIOL: boolean

  MEALVIOLATION: boolean
}

export default function calculateWorkShift (record: Record<string, any>) {
  const { SHIFTDT, FIRSTIN, FIRSTOUT, SECONDIN, SECONDOUT, THIRDIN, THIRDOUT } = record

  const result: CalculatedRecord = {
    errors: [],
    SHIFTDT: undefined,
    FIRSTIN: undefined,
    FIRSTOUT: undefined,
    SECONDIN: undefined,
    SECONDOUT: undefined,
    THIRDIN: undefined,
    THIRDOUT: undefined,

    'IDENTIFY INVALID ROW': false,

    SHIFTTLHRS: 0,

    SEG1LENGTH: 0,
    SEG2LENGTH: 0,
    SEG3LENGTH: 0,

    BREAK1LENGTH: 0,
    BREAK2LENGTH: 0,

    M1REQ: false,
    M1MISSED: false,
    M1LATE: false,
    M1LATEBY: 0,
    M1SHORT: false,
    M1SHORTBY: 0,
    M1VIOL: false,

    M2REQ: false,
    M2MISSED: false,
    M2LATE: false,
    M2LATEBY: 0,
    M2SHORT: false,
    M2SHORTBY: 0,
    M2VIOL: false,

    MEALVIOLATION: false
  }

  const exit = () => {
    const errors = !record[ID_COLUMN] ? [`${ID_COLUMN} is empty`] : []
    if (errors.length > 0 || result.errors.length > 0) {
      result['IDENTIFY INVALID ROW'] = true
    }
    return formatRecord(record, result, [...errors, ...result.errors])
  }

  // Check required fields
  result.errors = REQUIRED_FIELDS.filter(field => !record[field]).map(field => `${field} is missing`)
  if (result.errors.length) {
    return exit()
  }

  result.SHIFTDT = parseDate(SHIFTDT)
  const shiftDtError = hasError(result.SHIFTDT)
  if (shiftDtError) {
    result.errors = [...result.errors, `SHIFTDT ${shiftDtError}`]
    return exit()
  }

  // Parse and Validate First period
  result.FIRSTIN = parseDateTime(FIRSTIN, result.SHIFTDT)
  const firstInError = hasError(result.FIRSTIN, result.SHIFTDT, 'SHIFTDT', true)
  if (firstInError) {
    result.errors = [...result.errors, `FIRSTIN ${firstInError}`]
    return exit()
  }

  result.FIRSTOUT = parseDateTime(FIRSTOUT, result.SHIFTDT)
  const firstOutError = hasError(result.FIRSTOUT, result.FIRSTIN, 'FIRSTIN')
  if (firstOutError) {
    result.errors = [...result.errors, `FIRSTOUT ${firstOutError}`]
    return exit()
  }

  // Parse and Validate Second period
  if (SECONDIN) {
    result.SECONDIN = parseDateTime(SECONDIN, result.SHIFTDT)
    const secondInError = hasError(result.SECONDIN, result.FIRSTOUT, 'FIRSTOUT')
    if (secondInError) {
      result.errors = [...result.errors, `SECONDIN ${secondInError}`]
      return exit()
    }
  }

  if (!SECONDIN && SECONDOUT) {
    result.errors = [...result.errors, `SECONDIN is missing`]
    return exit()
  }

  if (SECONDIN && !SECONDOUT) {
    result.errors = [...result.errors, `SECONDOUT is missing`]
    return exit()
  }

  if (SECONDIN && SECONDOUT) {
    result.SECONDOUT = parseDateTime(SECONDOUT, result.SHIFTDT)
    const secondOutError = hasError(result.SECONDOUT, result.SECONDIN, 'SECONDIN')
    if (secondOutError) {
      result.errors = [...result.errors, `SECONDOUT ${secondOutError}`]
      return exit()
    }
  }

  // Parse and Validate Second period
  if (THIRDIN) {
    result.THIRDIN = parseDateTime(THIRDIN, result.SHIFTDT)
    const thirdInError = hasError(result.THIRDIN, result.SECONDOUT, 'SECONDOUT')
    if (thirdInError) {
      result.errors = [...result.errors, `THIRDIN ${thirdInError}`]
      return exit()
    }
  }

  if (!THIRDIN && THIRDOUT) {
    result.errors = [...result.errors, `THIRDIN is missing`]
    return exit()
  }

  if (THIRDIN && !THIRDOUT) {
    result.errors = [...result.errors, `THIRDOUT is missing`]
    return exit()
  }

  if (THIRDIN && THIRDOUT) {
    result.THIRDOUT = parseDateTime(THIRDOUT, result.SHIFTDT)
    const thirdOutError = hasError(result.THIRDOUT, result.THIRDIN, 'THIRDIN')
    if (thirdOutError) {
      result.errors = [...result.errors, `THIRDOUT ${thirdOutError}`]
      return exit()
    }
  }

  result.SEG1LENGTH = (result.FIRSTIN && result.FIRSTOUT) ? millisecondsToMinutes(result.FIRSTOUT.getTime() - result.FIRSTIN.getTime()) : 0
  result.SEG2LENGTH = (result.SECONDIN && result.SECONDOUT) ? millisecondsToMinutes(result.SECONDOUT.getTime() - result.SECONDIN.getTime()) : 0
  result.SEG3LENGTH = (result.THIRDIN && result.THIRDOUT) ? millisecondsToMinutes(result.THIRDOUT.getTime() - result.THIRDIN.getTime()) : 0

  result.BREAK1LENGTH = (result.FIRSTOUT && result.SECONDIN) ? millisecondsToMinutes(result.SECONDIN.getTime() - result.FIRSTOUT.getTime()) : 0
  result.BREAK2LENGTH = (result.THIRDIN && result.SECONDOUT) ? millisecondsToMinutes(result.THIRDIN.getTime() - result.SECONDOUT.getTime()) : 0

  result.SHIFTTLHRS = result.SEG1LENGTH + result.SEG2LENGTH + result.SEG3LENGTH

  result.M1REQ = result.SHIFTTLHRS > BREAK1_WORKING_HOURS
  if (result.M1REQ) {
    result.M1MISSED = !result.BREAK1LENGTH
    result.M1LATE = !result.M1MISSED && result.SEG1LENGTH > BREAK1_WORKING_HOURS
    result.M1LATEBY = result.M1LATE ? result.SEG1LENGTH - BREAK1_WORKING_HOURS : 0
    result.M1SHORT = !result.M1MISSED && result.BREAK1LENGTH < BREAK1_MIN_LENGTH
    result.M1SHORTBY = result.M1SHORT ? BREAK1_MIN_LENGTH - result.BREAK1LENGTH : 0
    result.M1VIOL = result.M1MISSED || result.M1LATE || result.M1SHORT
  }

  result.M2REQ = result.SHIFTTLHRS > BREAK2_WORKING_HOURS
  if (result.M2REQ) {
    result.M2MISSED = !result.BREAK2LENGTH
    result.M2LATE = !result.M2MISSED && (result.SEG1LENGTH + result.SEG2LENGTH) > BREAK2_WORKING_HOURS
    result.M2LATEBY = result.M2LATE ? (result.SEG1LENGTH + result.SEG2LENGTH) - BREAK2_WORKING_HOURS : 0
    result.M2SHORT = !result.M2MISSED && result.BREAK2LENGTH < BREAK2_MIN_LENGTH
    result.M2SHORTBY = result.M2SHORT ? BREAK2_MIN_LENGTH - result.BREAK2LENGTH : 0
    result.M2VIOL = result.M2MISSED || result.M2LATE || result.M2SHORT
  }

  result.MEALVIOLATION = result.M1VIOL || result.M2VIOL

  return exit()
}
