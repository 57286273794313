import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

const table = [
  ['SHIFTTLHRS', `Calculated - Total hours of the record's work shift`],
  ['SEG1LENGTH', `Calculated - Length of first time between first shift start and first meal break (in hours)`],
  ['SEG2LENGTH', `Calculated - Length of time between shift after first meal break and second meal break or end of shift (in hours)`],
  ['BREAK1LENGTH', `Calculated - Length of first meal break (in minutes)`],
  ['BREAK2LENGTH', `Calculated - Length of second meal break (in minutes)`],
  ['M1REQ', `Meal 1 required or not`],
  ['M1MISSED', `Meal 1 missed or not`],
  ['M1LATE', `Meal 1 late or not`],
  ['M1LATEBY', `If Meal 1 was late, how many minutes was it late by`],
  ['M1SHORT', `Meal 1 short or not`],
  ['M1SHORTBY', `If Meal 1 was short, how many minutes was it short by`],
  ['M1VIOL', `Flag - Meal 1 had violation or not`],
  ['M2REQ', `Meal 2 required or not`],
  ['M2MISSED', `Meal 2 missed or not`],
  ['M2LATE', `Meal 2 late or not`],
  ['M2LATEBY', `If Meal 2 was late, how many minutes was it late by`],
  ['M2SHORT', `Meal 2 short or not`],
  ['M2SHORTBY', `If Meal 2 was short, how many minutes was it short by`],
  ['M2VIOL', `Flag - Meal 2 had violation or not`],
  ['MEALVIOLATION', `Represents Meal 1 or Meal 2 violation as an overall violation flag - overall violation present or not`]
]

const ColumnsTable = () => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="Columns description" size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell>Column Name</TableCell>
          <TableCell>Meaning</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {table.map((row, index) => (
          <TableRow key={index}>
            <TableCell component="th" scope="row">
              {row[0]}
            </TableCell>
            <TableCell>{row[1]}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
)

export default ColumnsTable
