import UploadButton from '../../components/UploadButton'
import { Alert, AlertTitle, Box, Button, CircularProgress, Stack, Typography } from '@mui/material'
import { rootStore } from '../../store/RootStore'
import { observer } from 'mobx-react'
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from '@mui/icons-material'
import React, { useState } from 'react'
import { MAX_ROWS } from '../../store'
import { DataGrid, GridColDef } from '@mui/x-data-grid'

const Upload = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleButton = <Button
    color={'warning'}
    variant={'text'}
    size={'small'}
    endIcon={isExpanded ? <ArrowDropUp/> : <ArrowDropDown/>}
    onClick={() => setIsExpanded(!isExpanded)}>
    {isExpanded ? 'Collapse' : 'Expand'}
  </Button>

  const dataErrorColumns: GridColDef[] = [
    {
      width: 100,
      headerName: 'Line',
      field: 'rowIndex',
      sortable: false
    },
    {
      headerName: 'Error',
      field: 'error',
      flex: 2,
      sortable: false
    }]

  return <Box>
    <Typography variant="subtitle1" sx={{ color: 'primary.main' }}>Step 2</Typography>
    <Typography variant={'h4'}>Upload your data</Typography>

    <Alert severity={'info'} sx={{my: 3}}>
      <AlertTitle sx={{textDecoration: 'underline'}}>Your data will not be permanently stored in our systems</AlertTitle>
      <Typography variant="body1">It will be processed in your browser and removed from memory right after your web session ends.</Typography>
    </Alert>

    {/*<Typography sx={{ mt: 3 }}>*/}
    {/*  <span style={{fontWeight: 600, textDecoration: 'underline'}}>Your data will not be permanently stored in our systems.</span>*/}
    {/*</Typography>*/}
    {/*<Typography sx={{ mb: 3 }}>It will be processed in your browser and removed from memory right after your web session ends.</Typography>*/}

    {!rootStore.state.file && !rootStore.fileError && !rootStore.dataErrors.length &&
      <Stack direction={'row'} spacing={4} justifyContent={'flex-start'}>
        <Button variant={'outlined'} startIcon={<ArrowLeft/>} onClick={() => rootStore.prevStep()}>
          Go back
        </Button>
        <UploadButton/>
      </Stack>}

    {rootStore.state.file && <Alert severity={'info'}>
      <Stack direction={'row'} alignItems={'center'} gap={2}>
        <Typography sx={{ fontWeight: 500 }} variant={'body1'}>{rootStore.state.file.name}</Typography>
        {rootStore.isReading && <>
          <CircularProgress color="info" size={20}/>
          <Typography sx={{ fontWeight: 300 }} variant={'body2'}>Reading file</Typography>
        </>}
      </Stack>
    </Alert>}


    {rootStore.fileError && <Alert severity="error">
      <AlertTitle><Typography component={'span'}
                              color={'error'}>{rootStore.fileError.fileName}</Typography>: {rootStore.fileError.error}
      </AlertTitle>
      <Typography sx={{ my: 1.5 }} variant={'body2'}>
        We expect a CSV file format with comma separated values.
      </Typography>
      <Typography sx={{ my: 1.5 }} variant={'body2'}>
        Please download template and transfer your data into that format, then reupload your CSV file.
      </Typography>
      <Typography sx={{ my: 1.5 }} variant={'body2'}>
        Your file should contain no more than {MAX_ROWS.toLocaleString()} rows of data.
      </Typography>
      <Stack direction={'row'} justifyContent={'flex-start'} spacing={4}>
        <Button variant={'outlined'} startIcon={<ArrowLeft/>} color={'error'} onClick={() => rootStore.prevStep()}>
          Go back
        </Button>
        <UploadButton/>
      </Stack>
    </Alert>}

    {(rootStore.dataError || rootStore.dataErrors.length > 0) &&
      <Alert severity="warning" sx={{ my: 2, '& .MuiAlert-message': { flex: 2 } }}>
        <AlertTitle>Data Errors</AlertTitle>
        {rootStore.dataError && <Typography sx={{ my: 1.5 }} variant={'body2'}>{rootStore.dataError}</Typography>}
        {rootStore.dataErrors.length > 0 &&
          <Stack direction={'column'} alignItems={'start'} justifyContent={'flex-start'}>
            <Typography sx={{ my: 1.5 }} variant={'body2'}>There are some records in file which cannot be analyzed
              correctly due to
              missing/invalid data:</Typography>
            {toggleButton}
          </Stack>}

        {isExpanded && <Box sx={{ my: 1 }}>
          <DataGrid
            autoHeight
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: Math.min(rootStore.dataErrors.length, 10),
                },
              },
            }}
            pageSizeOptions={[Math.min(rootStore.dataErrors.length, 10)]}
            density={'compact'}
            rows={rootStore.dataErrors}
            columns={dataErrorColumns}
            getRowId={row => row.rowIndex}
            disableColumnMenu
            disableRowSelectionOnClick
            hideFooter={rootStore.dataErrors.length < 10}
          />
        </Box>}

        <Stack direction={'row'} justifyContent={'flex-start'} spacing={4} sx={{ mt: 2 }}>
          <Button variant={'outlined'} startIcon={<ArrowLeft/>} color={'warning'}
                  onClick={() => rootStore.prevStep()}>
            Go back
          </Button>
          <Button variant={'contained'} endIcon={<ArrowRight/>} color={'warning'}
                  onClick={() => rootStore.calculate()}>
            Ignore and analyze
          </Button>
        </Stack>
      </Alert>}
  </Box>
}

export default observer(Upload)
