import { makeAutoObservable } from 'mobx'
import { ID_COLUMN, REQUIRED_FIELDS } from './CalculateWorkShift'
import {
  ALLOWED_MIME_TYPES,
  CALCULATION_COMPLETE,
  CALCULATION_PROGRESS,
  CALCULATION_STARTED,
  ERASE_REPORT,
  ERROR,
  FILE_READING_COMPLETE,
  FILE_READING_STARTED,
  FOUND_SAVED_STATE,
  GENERATE_CSV,
  GENERATE_CSV_IS_COMPLETE,
  LOCAL_STORAGE_WELCOME_SCREEN_IS_HIDDEN,
  MAX_FILE_SIZE,
  MAX_ROWS,
  READ_FILE,
  RECEIVE_SAVED_STATE,
  REQUEST_SAVED_STATE,
  SAVING_COMPLETE,
  SAVING_STARTED,
  SESSION_STORAGE_KEY,
  START_CALCULATION
} from './index'
import { ReportState, UsageEvents } from './types'
import dayjs from 'dayjs'

import mixpanel from 'mixpanel-browser'

const fileSizeToMiB = (bytes: number) => Math.round(bytes / 1024 / 1024)

const downloadAction = (filename: string, text: string) => {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text))
  element.setAttribute('download', filename)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()
  document.body.removeChild(element)
}

export const STEPS = {
  EXAMPLE: 0,
  UPLOAD: 1,
  SUMMARY: 2,
  REPORT: 3
}

interface User {
  email?: string
}

class TimeCounter {
  startTime = 0
  endTime = 0

  get time () {
    return (this.endTime - this.startTime) / 1000
  }

  start = () => {
    this.startTime = this.endTime = Date.now()
  }
  stop = () => {
    this.endTime = Date.now()
  }
  reset = () => {
    this.startTime = this.endTime = 0
  }
}

const readingTimer = new TimeCounter()
const validationTimer = new TimeCounter()
const exportTimer = new TimeCounter()

interface FileError {
  fileName?: string,
  error?: string
}

class RootStore {
  state: ReportState = {}

  fileError?: FileError
  dataError?: string
  dataErrors: Array<Record<string, string>> = []

  progress = {
    total: 100,
    processed: 0
  }

  isReading = false
  isProcessing = false
  isPreparing = false
  isRestoring = false
  isExporting = false

  worker: Worker

  activeStep: number = STEPS.EXAMPLE

  welcomeScreen: boolean = true

  user: User = { email: undefined }

  constructor () {
    makeAutoObservable(this)

    if (process.env.REACT_APP_MIXPANEL_TOKEN) {
      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
        track_pageview: true,
        persistence: 'localStorage',
        ignore_dnt: true
      })
    }

    this.welcomeScreen = !localStorage.getItem(LOCAL_STORAGE_WELCOME_SCREEN_IS_HIDDEN)
    this.worker = new Worker(new URL('../worker.ts', import.meta.url))

    this.worker.onmessage = (e: any) => {
      const { type, payload, error } = e.data
      return this.handleWorkerMessage({ type, payload, error })
    }

    if (!sessionStorage.getItem(SESSION_STORAGE_KEY)) {
      this.erase()
      sessionStorage.setItem(SESSION_STORAGE_KEY, 'true')
    }

    this.worker.postMessage({ type: REQUEST_SAVED_STATE })
  }

  get fileNameParsed () {
    if (!this.state.file) {
      return []
    }
    const { name } = this.state.file
    const lastPointIndex = name.lastIndexOf('.')
    const nameWithoutExt = name.slice(0, lastPointIndex)
    const ext = name.slice(lastPointIndex + 1)
    return [nameWithoutExt, ext]
  }

  get fileSizeLabel () {
    if (!this.state.file) {
      return ''
    }
    const { size } = this.state.file
    if (size < 1024 * 1024) {
      return Math.round(size / 1024) + 'KiB'
    } else {
      return Math.round(size / 1024 / 1024) + 'MiB'
    }
  }

  get isReady (): boolean {
    return this.state.summary !== undefined && !this.isReading && !this.isProcessing
  }

  get invalidRecords () {
    if (this.state.summary) {
      return this.state.summary.invalidRecords
        .sort((a, b) => {
          return a.rowIndex > b.rowIndex ? 1 : a.rowIndex < b.rowIndex ? -1 : 0
        })
    }
    return []
  }

  handleWorkerMessage = ({ type, payload, error }: {
    type: String,
    payload: any | undefined,
    error: string | undefined
  }) => {

    if (type === FILE_READING_STARTED) {
      this.isReading = true
      return
    }

    if (type === FILE_READING_COMPLETE) {
      readingTimer.stop()
      this.isReading = false
      if (error) {
        this.fileError = { fileName: this.state.file?.name, error: error.toString() }
        this.trackEvent(UsageEvents.FileRejected, { error: this.fileError?.error })
        this.erase()
        return
      }
      this.validateColumns(payload)
    }

    if (type === CALCULATION_STARTED) {
      this.isProcessing = true
      this.activeStep = STEPS.SUMMARY
    }

    if (type === CALCULATION_COMPLETE) {
      validationTimer.stop()
      this.isProcessing = false
    }

    if (type === CALCULATION_PROGRESS) {
      const { total, processed } = payload
      this.progress = { total, processed }
      return
    }

    if (type === SAVING_STARTED) {
      this.isPreparing = true
    }

    if (type === SAVING_COMPLETE) {
      this.isPreparing = false
      this.receiveReport(payload)
      this.trackEvent(UsageEvents.DataValidated)
    }

    if (type === FOUND_SAVED_STATE) {
      this.isRestoring = true
    }

    if (type === RECEIVE_SAVED_STATE) {
      this.isRestoring = false
      this.receiveReport(payload)
    }

    if (type === GENERATE_CSV_IS_COMPLETE) {
      exportTimer.stop()
      setTimeout(() => {
        this.isExporting = false
        if (payload && this.user.email) {
          const [fileName] = this.fileNameParsed
          const dateTime = dayjs().format('MMDDYYYYHHmm')
          const [username, domain] = this.user.email.split('@')
          downloadAction(`${fileName}_${username}_${domain}_${dateTime}.csv`, payload)
          this.trackEvent(UsageEvents.ReportDownload, { userEmail: this.user.email })
        }
      }, 1000)
    }

    if (type === ERROR) {
      console.log(error)
    }
  }

  setFile = (file: File | undefined) => {
    this.erase()

    if (file) {
      this.fileError = undefined
      this.dataError = undefined
      this.dataErrors = []

      this.validateAndReadFile(file)
    }
  }

  validateAndReadFile = (file: File) => {
    const { name, size } = file
    this.state.file = { name, size }

    if (!ALLOWED_MIME_TYPES.includes(String(file.type).toLowerCase())) {
      this.fileError = { fileName: name, error: `File type is not supported` }
      this.trackEvent(UsageEvents.FileRejected, { error: this.fileError.error, filetype: file.type })
      this.erase()
      return
    }

    if (file.size > MAX_FILE_SIZE) {
      this.fileError = { fileName: name, error: `File is larger than ${fileSizeToMiB(MAX_FILE_SIZE)}MiB and cannot be parsed`}
      this.trackEvent(UsageEvents.FileRejected, { error: this.fileError.error })
      this.erase()
      return
    }

    readingTimer.start()
    this.worker.postMessage({ type: READ_FILE, payload: { file } })
  }

  validateColumns = ({ columns, rows }: { columns: Array<any>, rows: Array<any> }) => {
    const missingColumns = [ID_COLUMN, ...REQUIRED_FIELDS].filter(field => !columns.find(column => column.toUpperCase().trim() === field.toUpperCase()))

    if (missingColumns.length > 0) {
      this.fileError = { fileName: this.state.file?.name, error: `Missing required columns: ${missingColumns.join(', ')}`}
      this.trackEvent(UsageEvents.FileRejected, { error: this.fileError?.error })
      this.erase()
      return
    }

    if (rows.length > MAX_ROWS) {
      this.dataError = `Uploaded file contains more than ${MAX_ROWS.toLocaleString()} Record. Exceeded Record will not be analyzed.`
    }

    const rowsToBeAnalyzed = rows.slice(0, MAX_ROWS)
    const uniqueRows: Record<string, boolean> = {}

    rowsToBeAnalyzed.forEach((row, index) => {
      const rowUniqueKey = JSON.stringify(row)
      const rowIndex = (index + 2).toString()
      let error = ''

      if (Object.values(row).filter(Boolean).length === 0) {
        error = `Record is empty, skipped`
      }

      if (!error && uniqueRows.hasOwnProperty(rowUniqueKey)) {
        error = `Record is not unique, skipped`
      } else {
        uniqueRows[rowUniqueKey] = true
      }

      const emptyRequiredColumns = [ID_COLUMN, ...REQUIRED_FIELDS].filter(FIELD => !row[FIELD]?.trim())
      if (!error && emptyRequiredColumns.length > 0) {
        error = `Empty required columns: ${emptyRequiredColumns.join(', ')}`
      }

      if (error) {
        this.dataErrors.push({ rowIndex, error })
      }
    })

    this.trackEvent(UsageEvents.FileAccepted, {
      totalRecords: rowsToBeAnalyzed.length,
      warning: this.dataError || this.dataErrors.length ? `${this.dataErrors.length} invalid lines` : ''
    })

    if (!this.dataError && this.dataErrors.length === 0) {
      validationTimer.start()
      this.worker.postMessage({ type: START_CALCULATION })
    }
  }

  calculate = () => {
    this.dataError = undefined
    this.dataErrors = []
    validationTimer.start()
    this.worker.postMessage({ type: START_CALCULATION })
  }

  erase = () => {
    this.reset()

    sessionStorage.removeItem(SESSION_STORAGE_KEY)
    this.worker.postMessage({ type: ERASE_REPORT })
  }

  reset = () => {
    this.state = {}

    this.progress.total = 100
    this.progress.processed = 0

    this.isReading = false
    this.isProcessing = false
    this.isPreparing = false
    this.isRestoring = false
  }

  receiveReport = (state: ReportState) => {
    this.state = state
    if (this.isReady) {
      this.activeStep = STEPS.SUMMARY
    }
  }

  exportAsCSV = () => {
    this.isExporting = true
    this.activeStep = STEPS.REPORT
    exportTimer.start()
    this.worker.postMessage({ type: GENERATE_CSV })
  }

  setActiveStep = (step: number) => {
    if (step === STEPS.EXAMPLE) {
      this.fileError = undefined
      this.dataError = undefined
      this.dataErrors = []
      this.erase()
    }

    if (step === STEPS.UPLOAD) {
      this.erase()
    }

    this.activeStep = step
  }
  prevStep = () => this.setActiveStep(Math.max(this.activeStep - 1, STEPS.EXAMPLE))
  nextStep = () => this.setActiveStep(Math.min(this.activeStep + 1, STEPS.REPORT))

  toggleWelcomeScreen = (isVisible = false) => {
    this.setActiveStep(STEPS.EXAMPLE)
    this.welcomeScreen = isVisible
    if (isVisible) {
      localStorage.removeItem(LOCAL_STORAGE_WELCOME_SCREEN_IS_HIDDEN)
    } else {
      localStorage.setItem(LOCAL_STORAGE_WELCOME_SCREEN_IS_HIDDEN, '1')
    }
  }

  setUser = (user: any) => {
    this.user = user
  }

  trackEvent = (eventName: UsageEvents, data?: Record<string, string | number | undefined | null>) => {
    const summary: Record<string, string | number | undefined | null> = {
      fileName: this.state.file?.name,
      fileSize: this.fileSizeLabel,
      totalRecords: this.state.rows?.length,
    }

    if (this.state.summary) {
      summary.validRecords = this.state.summary?.validRecords
      summary.invalidRecords = this.state.summary?.invalidRecords.length
      summary.violations = this.state.summary?.violations
      summary.violationsPercentage = this.state.summary?.violationsPercentage
    }

    if (eventName === UsageEvents.DataValidated || eventName === UsageEvents.ReportDownload) {
      summary.readingTime = readingTimer.time
      summary.validationTime = validationTimer.time
    }

    if (eventName === UsageEvents.ReportDownload) {
      summary.exportTime = exportTimer.time
    }

    mixpanel.track(eventName, { ...summary, ...data })
  }
}

export const rootStore = new RootStore()
