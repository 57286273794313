import { rootStore, STEPS } from '../../store/RootStore'
import { CloudDownload, FileDownload, FileUpload, Grading } from '@mui/icons-material'
import { StyledTab, StyledTabs } from './styles'
import { Avatar } from '@mui/material'
import { FC, ReactElement } from 'react'

const TabIcon = (Icon: ReactElement, index: number) => {
  return (
    <Avatar sx={{ width: 32, height: 32, bgcolor: rootStore.activeStep >= index ? 'primary.main' : 'secondary.main' }}>
      {Icon}
    </Avatar>
  )
}

const Tabs: FC = () => {
  return (
    <StyledTabs value={rootStore.activeStep} orientation="vertical">
      <StyledTab label="Prepare your data"
                 iconPosition="end"
                 icon={TabIcon(<FileDownload sx={{ width: 20, height: 20 }}/>, 0)}
                 onClick={() => rootStore.setActiveStep(STEPS.EXAMPLE)}/>
      <StyledTab label="Upload your data"
                 iconPosition="end"
                 icon={TabIcon(<FileUpload sx={{ width: 20, height: 20 }}/>, 1)}
                 onClick={() => rootStore.setActiveStep(STEPS.UPLOAD)}/>
      <StyledTab label="Summary of your results"
                 iconPosition="end"
                 icon={TabIcon(<Grading sx={{ width: 20, height: 20 }}/>, 2)}
                 onClick={() => rootStore.setActiveStep(STEPS.SUMMARY)}
                 disabled={!(rootStore.isProcessing || rootStore.isReady)}/>
      <StyledTab label="Download detailed results"
                 iconPosition="end"
                 icon={TabIcon(<CloudDownload sx={{ width: 20, height: 20 }}/>, 3)}
                 onClick={() => rootStore.setActiveStep(STEPS.REPORT)}
                 disabled={!(rootStore.isProcessing || rootStore.isReady)}/>
    </StyledTabs>
  )
}

export default Tabs
