import { Box, Container, Grid, Link, Tooltip } from '@mui/material'
import { ArrowOutward } from '@mui/icons-material'

const Footer = () =>
  <Box
    component="footer"
    sx={{
      mt: 'auto',
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme => theme.palette.secondary.main,
    }}
  >
    <Container maxWidth="lg" sx={{ height: '80px' }}>
      <Grid container spacing={2}>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
          <Link href="https://app.termly.io/document/terms-of-service/8940d467-b3dc-4bfc-839f-fa46d2723177"
                underline="none" target="_blank" rel="noreferrer" sx={{ display: 'flex', gap: 1 }}>
            Terms and Conditions
            <Tooltip title={'This link will be opened in a new tab'} placement={'top'} arrow>
              <ArrowOutward sx={{ width: '16px' }}/>
            </Tooltip>
          </Link>
        </Grid>
        <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', height: '60px', alignItems: 'center' }}>
          <Link href="https://app.termly.io/document/privacy-policy/aeb13a11-95a0-4036-b77d-cdace6510f2b"
                underline="none" target="_blank" rel="noreferrer" sx={{ display: 'flex', gap: 1 }}>
            Privacy Policy
            <Tooltip title={'This link will be opened in a new tab'} placement={'top'} arrow>
              <ArrowOutward sx={{ width: '16px' }}/>
            </Tooltip>
          </Link>
        </Grid>
      </Grid>
    </Container>
  </Box>

export default Footer
