import { observer } from 'mobx-react'
import React, { ChangeEvent, useCallback, useRef } from 'react'
import { rootStore } from '../store/RootStore'
import { Description, FileUpload } from '@mui/icons-material'
import { Button, styled, Typography } from '@mui/material'

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
})

function UploadButton () {
  const inputRef = useRef<HTMLInputElement | null>(null)

  const handleFileChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return
    }
    const file = e.target.files[0]
    rootStore.setFile(file)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }, [])

  return !rootStore.state.file ? (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      startIcon={<FileUpload/>}
    >
      Upload CSV file
      <VisuallyHiddenInput type="file" ref={inputRef} onChange={handleFileChange}/>
    </Button>
  ) : (
    <Button variant={'contained'} color={'success'} startIcon={<Description/>}>
      {rootStore.state.file.name}
      <Typography variant={'overline'} sx={{ ml: 1 }}>{rootStore.fileSizeLabel}</Typography>
    </Button>
  )
}

export default observer(UploadButton)
