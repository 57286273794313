export type MealSummary = {
  required: number,
  missed: number,
  late: number,
  short: number,
  violations: number
}

export type ReportState = {
  file?: {
    name: string,
    size: number
  },
  columns?: Array<string>
  rows?: Array<any>
  summary?: {
    records: number,
    violations: number,
    violationsPercentage: number,
    meal1: MealSummary,
    meal2: MealSummary,
    validRecords: number,
    invalidRecords: Array<{ rowIndex: number, error: string }>,
  }
}

export enum UsageEvents {
  FileUpload = 'FileUpload',
  FileAccepted = 'FileAccepted',
  FileRejected = 'FileRejected',
  DataValidated = 'DataValidated',
  ReportDownload = 'ReportDownload'
}
