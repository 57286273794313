import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import React from 'react'

const ColumnsTable = () => (
  <TableContainer component={Paper}>
    <Table sx={{ minWidth: 650 }} aria-label="Columns description" size={'small'}>
      <TableHead>
        <TableRow>
          <TableCell>Column Name</TableCell>
          <TableCell>Meaning</TableCell>
          <TableCell>Expected data</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            EMPID
          </TableCell>
          <TableCell>Unique Employee ID, <strong>required</strong></TableCell>
          <TableCell>Numeric values</TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">SHFTDT</TableCell>
          <TableCell>Date of employee’s shirt, <strong>required</strong>.<br/>If employee did not have a shift on this date, do not include this record</TableCell>
          <TableCell>
            MM/DD/YYYY date format<br/>
            Example:<br/>
            10/02/2023
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">FIRSTIN</TableCell>
          <TableCell>First clock-in timestamp for this day’s shift, <strong>required</strong></TableCell>
          <TableCell>
            MM/DD/YY HH:MM timestamp in 24hr format<br/>
            Example:<br/>
            10/02/2023 16:07<br/>
            Indicates first clock in timestamp at 4:07PM on Oct 2, 2024
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">FIRSTOUT</TableCell>
          <TableCell>First clock-out timestamp for this day’s shift, <strong>required</strong></TableCell>
          <TableCell>Same format as FIRSTIN column</TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">SECONDIN</TableCell>
          <TableCell>Second clock-in timestamp for this day’s shift, <i>optional</i></TableCell>
          <TableCell>Same format as FIRSTIN column</TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">SECONDOUT</TableCell>
          <TableCell>Second clock-out timestamp for this day’s shift, <i>optional</i></TableCell>
          <TableCell>Same format as FIRSTIN column</TableCell>
        </TableRow>

        <TableRow>
          <TableCell component="th" scope="row">THIRDIN</TableCell>
          <TableCell>Third clock-in timestamp for this day’s shift, <i>optional</i></TableCell>
          <TableCell>Same format as FIRSTIN column</TableCell>
        </TableRow>

        <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
          <TableCell component="th" scope="row">THIRDOUT</TableCell>
          <TableCell>Third clock-out timestamp for this day’s shift, <i>optional</i></TableCell>
          <TableCell>Same format as FIRSTIN column</TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </TableContainer>
);

export default ColumnsTable
