import { rootStore } from '../store/RootStore'
import React from 'react'
import { Box, Button } from '@mui/material'
import { Description } from '@mui/icons-material'
import { observer } from 'mobx-react'

const DownloadButton = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
      <Button variant={'contained'} startIcon={<Description/>} onClick={() => rootStore.exportAsCSV()}
              disabled={rootStore.isExporting}>
        Download details
      </Button>
    </Box>
  )
}

export default observer(DownloadButton)
